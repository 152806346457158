import flattenData from 'utils/flatten-data';
import actionTypes from '../constants/actionTypes';
import createListingReducer from './createListingReducer';
import createListOptionsReducer from './createListOptionsReducer';

/**
 * default flattenData only goes so deep,
 * so we are going to grab the details object and flatten that out as well.
 */
function flattenLearningPlan({ requirements, ...rest }) {
  return {
    ...flattenData(rest),
    requirements: (requirements || []).map(({ details, ...restReq }) => ({
      ...restReq,
      ...flattenData(details),
    })),
  };
}

export default function learningPlans(state = {}, action = {}) {
  const optionsReducer = createListOptionsReducer({
    loadingAction: actionTypes.LEARNING_PLANS_LIST_LOADING,
    resetConcatActions: [
      actionTypes.ADD_LEARNING_PLAN_SUCCESS,
      actionTypes.PATCH_LEARNING_PLAN_SUCCESS,
      actionTypes.DELETE_LEARNING_PLAN_SUCCESS,
    ],
  });
  const listingReducer = createListingReducer({
    singleSuccessAction: actionTypes.GET_LEARNING_PLAN_SUCCESS,
    successAction: actionTypes.LEARNING_PLANS_LIST_SUCCESS,
    loadingAction: actionTypes.LEARNING_PLANS_LIST_LOADING,
    errorAction: actionTypes.LEARNING_PLANS_LIST_ERROR,
    flattenMethod: flattenLearningPlan,
  });

  const options = optionsReducer(state.options, action);
  const list = listingReducer(state.list, action, options);

  return {
    list,
    options,
  };
}
