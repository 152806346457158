/**
 * Goal of this file is to to boot up all of Managers stores and the History object
 * to be used in any react renders that happen on the page. This allows them all
 * to share the same stores and history easily.
 */
import polyfill from 'src/polyfills';

import FetchUtils from 'utils/fetch-utils';
import configureStore from 'store/configureStore';
import { loadConfiguration } from 'actions/configActions';
import { getCookie } from 'actions/cookiesActions';

// Let the fetch service know what client we're interacting with
const clientName = window.URL_ROOT ? window.URL_ROOT.replace('/', '') : null;
FetchUtils.setClient(clientName);

global.alchemyStore = configureStore();

// Autoload messages
polyfill(window.messagesLocale);

// Load up the tunables defined in the html.
// We can always load more if needed later
window.alchemyStore.dispatch(loadConfiguration(window.tunables));
window.alchemyStore.dispatch(getCookie('pageLocale'));
