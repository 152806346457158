import AppConstants from 'constants/appconstants';
import flattenData from 'utils/flatten-data';
import parseIntHeader from 'utils/parseIntHeader';

/**
 * @constant
 * @type {Object}
 * @property {bool} isLoading - If true, result data is currently being loaded
 * @property {bool} hasLoaded - If true, result data has been loaded or there is an error
 * @property {bool} hasError - If true, error in {@link module:reducers~errors errors reducer}
 * @property {array} data - The data for the listing
 * @property {number} totalPages - The total number of pages possible for this listing
 * @property {number} totalCount - The total number of records possible for this listing
 */
export const defaultInitialState = {
  isLoading: false,
  hasLoaded: false,
  hasError: false,
  byId: {},
  ids: [],
  totalCount: 0,
  totalPages: 0,
  allPagesLoaded: false,
};

/**
 * Generates a default reducer for listings
 * @param {Object} options - Options for the reducer.
 * @param {Object} options.initialState - The initial state of the reducer
 * Defaults to {@link defaultInitialState}
 * @param {function} options.flattenMethod - Method to use to flatten the returned data.
 * Defaults to {@link flattenData}
 * @param {string} options.singleSuccessAction - Action when a single row is loaded
 * @param {string} options.supplementalListAction - Action to add rows to the data list
 *   but not add them to the ids list. This is helpful for getting "selected"
 *   items in a list where the are fetch outside the normal order of the listing.
 * @param {string} options.successAction - The success action
 * @param {string} options.loadingAction - The loading action
 * @param {string} options.errorAction - The error action
 * @return {function} The generated reducer function
 */
export default function createListingReducer({
  initialState = defaultInitialState,
  flattenMethod = flattenData,
  singleSuccessAction = 'UNUSED_ACTION',
  supplementalListAction = 'UNUSED_ACTION',
  successAction,
  loadingAction,
  errorAction,
}) {
  /**
   * @param {Object} state - The state of this reducer, Defaults to the provided initialState
   * @param {Object} action - The current action
   * @param {Object} action.response - The action's response including headers and data
   * @param {string} action.type - The type of action
   * @param {Object} options - Options from the optionsReducer
   * @param {bool} options.concatResults - If true, data should replace existing data
   * rather than being concatenated to the end of it
   * @return {Object} The reducers new state
   */
  return function ListingReducer(
    state = initialState,
    action = {},
    { concatResults = false } = {},
  ) {
    const {
      response, type, page,
      perPage = AppConstants.PER_PAGE,
    } = action;

    switch (type) {
      case singleSuccessAction: {
        const { data } = response;

        return {
          isLoading: false,
          hasLoaded: true,
          hasError: false,
          byId: { [data.id]: flattenMethod(data) },
          ids: [data.id],
          totalPages: 0,
          totalCount: 0,
          allPagesLoaded: true,
        };
      }
      case successAction: {
        let totalCount = parseIntHeader(response.headers, 'X-Total-Count');
        // Locales doesn't have headers
        if (!totalCount) { totalCount = response.data.length; }
        const ids = response.data.map((obj) => obj.id);
        const list = response.data.reduce((obj, entity) => (
          {
            ...obj,
            [entity.id]: flattenMethod(entity),
          }
        ), {});

        // This handles the case when page is undefined as is the case for most
        // of the current actions. In that case we can guess what page it is.
        const currentPage = page || Math.floor(state.ids.length / perPage) + 1;
        const index = (currentPage - 1) * perPage;
        const filler = index > state.ids.length ? Array.from(Array(index - state.ids.length)) : [];
        const totalPages = Math.ceil(totalCount / perPage);

        // if our response has an entry stating this is all the data, don't concat.
        const returnIds = (concatResults && !response.allData)
          /**
           * We need to insert the new data in the correct place in the array
           * based off of the current page. It is possible that some previous
           * pages haven't loaded yet. We handle this by adding "filler" entries
           * into the array so that we can place the new data at the correct indexes.
           */
          ? [
            // existing data that comes before the new ids
            ...state.ids.slice(0, index),
            // any filler entries we need to add to pad the indexs
            ...filler,
            // the new data
            ...ids,
            // the rest of the existing data excluding possible filler entries we just replaced
            ...state.ids.slice(index + ids.length),
          ]
          : ids;

        const allDataFilled = returnIds.indexOf(undefined) === -1;

        return {
          isLoading: false,
          hasLoaded: true,
          hasError: false,
          byId: { ...state.byId, ...list },
          ids: returnIds,
          totalPages,
          totalCount,
          allPagesLoaded: allDataFilled && returnIds.length === totalCount,
        };
      }

      case supplementalListAction: {
        const list = response.data.reduce((obj, entity) => (
          {
            ...obj,
            [entity.id]: flattenMethod(entity),
          }
        ), {});

        return {
          ...state,
          isLoading: false,
          hasLoaded: true,
          hasError: false,
          byId: { ...state.byId, ...list },
          allPagesLoaded: true,
        };
      }

      case loadingAction:
        return {
          isLoading: true,
          hasLoaded: false,
          hasError: false,
          byId: { ...state.byId },
          ids: (concatResults) ? state.ids.slice(0) : [],
          totalPages: state.totalPages,
          totalCount: state.totalCount,
          allPagesLoaded: concatResults ? state.allPagesLoaded : false,
        };

      case errorAction:
        return {
          isLoading: false,
          hasLoaded: true,
          hasError: true,
          byId: { ...state.byId },
          ids: [],
        };

      default:
        return state;
    }
  };
}

export function getById(state, id) {
  if (!state.byId) return null;
  return state.byId[id];
}

export function getDataList(state) {
  if (!state.ids) return [];
  return state.ids.map((id) => getById(state, id));
}
