import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/array';
import 'core-js/es/number';
import 'core-js/es/object/assign';
import 'core-js/es/object/entries';
import 'core-js/es/object/from-entries';
import 'core-js/es/object/values';
import 'core-js/es/set';
import 'core-js/es/string/ends-with';
import 'core-js/es/string/includes';
import 'core-js/es/string/starts-with';
import 'core-js/es/symbol/iterator';
import 'core-js/features/promise/finally';
import 'core-js/features/url';
import 'core-js/features/url-search-params';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import { shouldPolyfill as shouldPolyfillCanonicallLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillListFormat } from '@formatjs/intl-listformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill';

export default async function polyfill(locale) {
  const requests = [];

  if (shouldPolyfillCanonicallLocales()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
  }

  if (shouldPolyfillLocale()) {
    await import('@formatjs/intl-locale/polyfill');
  }

  const polyfills = {
    PluralRules: {
      unsupportedLocale: null,
      async doImport(unsupportedLocale) {
        // Load the polyfill 1st BEFORE loading data
        requests.push(
          import('@formatjs/intl-pluralrules/polyfill-force'),
          import(`@formatjs/intl-pluralrules/locale-data/${unsupportedLocale}`),
        );
      },
    },
    ListFormat: {
      unsupportedLocale: null,
      async doImport(unsupportedLocale) {
        // Load the polyfill 1st BEFORE loading data
        requests.push(
          import('@formatjs/intl-listformat/polyfill-force'),
          import(`@formatjs/intl-listformat/locale-data/${unsupportedLocale}`),
        );
      },
    },
    NumberFormat: {
      unsupportedLocale: null,
      async doImport(unsupportedLocale) {
        // Load the polyfill 1st BEFORE loading data
        requests.push(
          import('@formatjs/intl-numberformat/polyfill-force'),
          import(`@formatjs/intl-numberformat/locale-data/${unsupportedLocale}`),
        );
      },
    },
    RelativeTimeFormat: {
      unsupportedLocale: null,
      async doImport(unsupportedLocale) {
        // Load the polyfill 1st BEFORE loading data
        requests.push(
          import('@formatjs/intl-relativetimeformat/polyfill-force'),
          import(`@formatjs/intl-relativetimeformat/locale-data/${unsupportedLocale}`),
        );
      },
    },
    DateTimeFormat: {
      unsupportedLocale: null,
      async doImport(unsupportedLocale) {
        requests.push(
          import('@formatjs/intl-datetimeformat/polyfill-force'),
          import('@formatjs/intl-datetimeformat/add-all-tz'),
          import(`@formatjs/intl-datetimeformat/locale-data/${unsupportedLocale}`),
        );
      },
    },
  };

  switch (locale) {
    case 'es-US':
      polyfills.PluralRules.unsupportedLocale = shouldPolyfillPluralRules('es');
      polyfills.ListFormat.unsupportedLocale = shouldPolyfillListFormat(locale);
      polyfills.NumberFormat.unsupportedLocale = shouldPolyfillNumberFormat(locale);
      polyfills.RelativeTimeFormat.unsupportedLocale = shouldPolyfillRelativeTimeFormat(locale);
      polyfills.DateTimeFormat.unsupportedLocale = shouldPolyfillDateTimeFormat(locale);
      break;
    case 'fr-CA':
      polyfills.PluralRules.unsupportedLocale = shouldPolyfillPluralRules('fr');
      polyfills.ListFormat.unsupportedLocale = shouldPolyfillListFormat(locale);
      polyfills.NumberFormat.unsupportedLocale = shouldPolyfillNumberFormat(locale);
      polyfills.RelativeTimeFormat.unsupportedLocale = shouldPolyfillRelativeTimeFormat(locale);
      polyfills.DateTimeFormat.unsupportedLocale = shouldPolyfillDateTimeFormat(locale);
      break;
    case 'en-GB':
      polyfills.PluralRules.unsupportedLocale = shouldPolyfillPluralRules('en');
      polyfills.ListFormat.unsupportedLocale = shouldPolyfillListFormat(locale);
      polyfills.NumberFormat.unsupportedLocale = shouldPolyfillNumberFormat(locale);
      polyfills.RelativeTimeFormat.unsupportedLocale = shouldPolyfillRelativeTimeFormat(locale);
      polyfills.DateTimeFormat.unsupportedLocale = shouldPolyfillDateTimeFormat(locale);
      break;
    case 'es-MX':
      polyfills.PluralRules.unsupportedLocale = shouldPolyfillPluralRules('es');
      polyfills.ListFormat.unsupportedLocale = shouldPolyfillListFormat(locale);
      polyfills.NumberFormat.unsupportedLocale = shouldPolyfillNumberFormat(locale);
      polyfills.RelativeTimeFormat.unsupportedLocale = shouldPolyfillRelativeTimeFormat(locale);
      polyfills.DateTimeFormat.unsupportedLocale = shouldPolyfillDateTimeFormat(locale);
      break;
    case 'pt-BR':
      polyfills.PluralRules.unsupportedLocale = shouldPolyfillPluralRules('pt');
      polyfills.ListFormat.unsupportedLocale = shouldPolyfillListFormat('pt');
      polyfills.NumberFormat.unsupportedLocale = shouldPolyfillNumberFormat('pt');
      polyfills.RelativeTimeFormat.unsupportedLocale = shouldPolyfillRelativeTimeFormat('pt');
      polyfills.DateTimeFormat.unsupportedLocale = shouldPolyfillDateTimeFormat('pt');
      break;
    case 'fr-FR':
      polyfills.PluralRules.unsupportedLocale = shouldPolyfillPluralRules('fr');
      polyfills.ListFormat.unsupportedLocale = shouldPolyfillListFormat('fr');
      polyfills.NumberFormat.unsupportedLocale = shouldPolyfillNumberFormat('fr');
      polyfills.RelativeTimeFormat.unsupportedLocale = shouldPolyfillRelativeTimeFormat('fr');
      polyfills.DateTimeFormat.unsupportedLocale = shouldPolyfillDateTimeFormat('fr');
      break;
    case 'de-DE':
      polyfills.PluralRules.unsupportedLocale = shouldPolyfillPluralRules('de');
      polyfills.ListFormat.unsupportedLocale = shouldPolyfillListFormat('de');
      polyfills.NumberFormat.unsupportedLocale = shouldPolyfillNumberFormat('de');
      polyfills.RelativeTimeFormat.unsupportedLocale = shouldPolyfillRelativeTimeFormat('de');
      polyfills.DateTimeFormat.unsupportedLocale = shouldPolyfillDateTimeFormat('de');
      break;
    case 'ru-RU':
      polyfills.PluralRules.unsupportedLocale = shouldPolyfillPluralRules('ru');
      polyfills.ListFormat.unsupportedLocale = shouldPolyfillListFormat('ru');
      polyfills.NumberFormat.unsupportedLocale = shouldPolyfillNumberFormat('ru');
      polyfills.RelativeTimeFormat.unsupportedLocale = shouldPolyfillRelativeTimeFormat('ru');
      polyfills.DateTimeFormat.unsupportedLocale = shouldPolyfillDateTimeFormat('ru');
      break;
    case 'en-US':
    default:
      polyfills.PluralRules.unsupportedLocale = shouldPolyfillPluralRules('en');
      polyfills.ListFormat.unsupportedLocale = shouldPolyfillListFormat('en');
      polyfills.NumberFormat.unsupportedLocale = shouldPolyfillNumberFormat('en');
      polyfills.RelativeTimeFormat.unsupportedLocale = shouldPolyfillRelativeTimeFormat('en');
      polyfills.DateTimeFormat.unsupportedLocale = shouldPolyfillDateTimeFormat('en');
      break;
  }

  await Promise.all(
    Object.values(polyfills).map(async (intlPolyfill) => {
      if (!intlPolyfill.unsupportedLocale) {
        return;
      }
      await intlPolyfill.doImport(intlPolyfill.unsupportedLocale);
    }),
  );
}
