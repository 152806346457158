import _ from 'lodash';

import { flattenObject } from './objectUtils';

export default function flattenData(obj) {
  const flatData = flattenObject(obj);

  Object.keys(flatData).forEach((key) => {
    const newKey = _.camelCase(key);

    if (flatData[newKey] !== flatData[key]) {
      flatData[newKey] = flatData[key];
      delete flatData[key];
    }
  });

  return flatData;
}

/**
 * Don't flatten 'changes' in the history object as we want to iterate through them
 */
export function flattenHistory(data) {
  const { changes, ...leftOverData } = data;
  const flattened = flattenData(leftOverData);
  return { changes, ...flattened };
}
