import _ from 'lodash';
import actionTypes from '../constants/actionTypes';

const initialState = {
  isLoading: false,
  hasLoaded: false,
  hasError: false,
  options: [],
};

/**
 * This function generates an array of groups that contains objects with the following structure:
 * groupId: id for the group
 * groupName: name of the group
 * options: this is an array that has the options available for the group. Each option is an object
 * with an Id and a text property.
 * @param {array} groups - Existing list of groups. The new items are added to this list.
 * @param {array} data - List of supervised groups returned by the endpoint.
 * @return {array} List of group options
 */
function buildOptionsGroups(groups, data) {
  data.forEach((item) => {
    const groupIndex = _.findIndex(groups, (group) => group.groupName === item.type);
    if (groupIndex !== -1) {
      groups[groupIndex].options.push({
        id: item.id,
        text: item.name,
      });
    } else {
      groups.push({
        groupId: item.type,
        groupName: item.type,
        options: [{
          id: item.id,
          text: item.name,
        }],
      });
    }
  });
  return groups;
}

export default function supervisedGroupsOptions(state = initialState, action = {}) {
  const { type, response } = action;
  switch (type) {
    case actionTypes.SUPERVISED_GROUPS_OPTIONS_SUCCESS:
      return {
        options: buildOptionsGroups(state.options, response.data),
        isLoading: false,
        hasLoaded: true,
        hasError: false,
      };

    case actionTypes.SUPERVISED_GROUPS_OPTIONS_LOADING:
      return {
        isLoading: true,
        hasLoaded: false,
        hasError: false,
        options: state.options,
      };

    case actionTypes.SUPERVISED_GROUPS_OPTIONS_ERROR:
      return {
        isLoading: false,
        hasLoaded: true,
        hasError: true,
      };

    default:
      return state;
  }
}
