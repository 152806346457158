import _ from 'lodash';

export function objectToSnake(object) {
  return Object.keys(object).reduce((newObject, key) => {
    const newKey = _.snakeCase(key);
    const value = object[key];
    return { ...newObject, [newKey]: value };
  }, {});
}

export function flattenObject(object, prefix = '') {
  return Object.keys(object).reduce((newObject, key) => {
    const value = object[key];
    const prefixedKey = _.camelCase(prefix ? `${prefix}.${key}` : key);
    const returnedObject = { ...newObject };

    if (
      typeof value === 'string'
      || typeof value === 'boolean'
      || _.isNumber(value)
      || value === null
      || Array.isArray(value)
    ) {
      returnedObject[prefixedKey] = value;
    } else {
      Object.assign(returnedObject, flattenObject(value, prefixedKey));
    }

    return returnedObject;
  }, {});
}

export function removeNullKeys(obj) {
  return Object.entries(obj).reduce((prevValue, [key, value]) => (value.op === null
    ? prevValue : { ...prevValue, [key]: value }), {});
}

export function removeNullValues(obj) {
  return Object.entries(obj).reduce((prevValue, [key, value]) => (value.value === 'false' || value.op === null
    ? prevValue : { ...prevValue, [key]: value }), {});
}
