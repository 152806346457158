import pendingCorrectiveActionsList from './pendingCorrectiveActionsList';
import pendingCorrectiveActionsListOptions from './pendingCorrectiveActionsListOptions';

export default function pendingCorrectiveActions(state = {}, action = {}) {
  const options = pendingCorrectiveActionsListOptions(state.options, action);
  const list = pendingCorrectiveActionsList(state.list, action, options);

  return {
    options,
    list,
  };
}
