const initialState = {
};

export default function cookies(state = initialState, action = {}) {
  switch (action.type) {
    case 'setCookie':
    case 'getCookie':
      return {
        ...state,
        [action.name]: action.value,
      };

    default:
      return state;
  }
}
