import actionTypes from 'constants/actionTypes';
import createListingReducer from './createListingReducer';

/**
 * @module reducers/usersPendingList
 */

export default createListingReducer({
  singleSuccessAction: actionTypes.PENDING_USER_FETCH_SUCCESS,
  successAction: actionTypes.PENDING_USERS_LIST_SUCCESS,
  loadingAction: actionTypes.PENDING_USERS_LIST_LOADING,
  errorAction: actionTypes.PENDING_USERS_LIST_ERROR,
});
