import actionTypes from '../constants/actionTypes';
import createListOptionsReducer from './createListOptionsReducer';

/**
  * @module reducers/coursesActivitiesOptions
  */

/**
  * coursesActivitiesListOptions Reducer function
  * @function coursesActivitiesListOptions
  * @param {Object} state - defaults to
  * {@link module:reducers/createListOptionsReducer~defaultInitialState}
  * @param {Object} action - Action
  * @return {Object} The reducer's state
  */
export default createListOptionsReducer({
  loadingAction: actionTypes.COURSES_ACTIVITIES_LOADING,
});
