import _ from 'lodash';
import actionTypes from 'constants/actionTypes';
import flattenData from 'utils/flatten-data';
import createListingReducer from './createListingReducer';

/**
 * @module reducers/userTranscriptsList
 */

/**
 * @typedef Transcripts
 * @type {Object}
 * @property {number} id - Id of the transcript in the database
 * @property {Object} user - user who took the training
 * @property {Object} course - course that was taken
 * @property {Object} class - class that was taken
 * @property {TestSession[]} test_sessions - list of
 *  {@link module:reducers/testSession~TestSession testSession} objects
 * @property {EvalSession[]} eval_sessions - list of
 *  {@link module:reducers/evalSession~EvalSession evalSession} objects
 * @property {ObservationtSession[]} observation_sessions - list of
 *  {@link module:reducers/observationtSession~ObservationtSession observationtSession} objects
 * @property {string} type - saved record of the type of course was taken
 * @property {string} source - describes the source of the transcript.
 *  Current options: playbook, player, coach, online, other
 * @property {string} status - current status of the transcript.
 *  Options: testing, passed, failed, in-progress
 * @property {string} code - saved record of the course code, needed in cases
 *  where course has been deleted or transcript is from an external source
 * @property {string} name - saved record of the course name, needed in cases
 *  where course has been deleted or transcript is from an external source
 * @property {date} start_date - when the training started
 * @property {date} end_date - when the training ended
 * @property {date} credit_date - when credit for the transcript was given
 * @property {date} import_date - if the transcript was imported from an
 *  external source this is the date that the import occurred
 * @property {string} external_id - customer specified string that is used when
 *  processing transcripts in external systems
 * @property {date} created_at - date that the transcript was originally created
 * @property {date} modified_at - date that the transcript was last modified
 */

export function computeScore(transcript) {
  // only one of our sessions will have a grade. Search them all and find the single record.
  const session = Object.keys(transcript).find((key) => key.indexOf('SessionGrade') > -1);
  const grade = transcript[session];

  // ensure the grade is a number and valid. If so, convert it to a percent value.
  if (_.isNumber(grade) && grade >= 0 && grade <= 100) {
    return grade / 100;
  }
  return null;
}

export function getFacilitator({ facilitatorFirstName, facilitatorLastName }) {
  if (facilitatorFirstName === undefined || facilitatorLastName === undefined) {
    return '--';
  }
  return `${facilitatorLastName}, ${facilitatorFirstName}`;
}

export function flattenTranscript(transcript) {
  const flattenedTranscript = flattenData(transcript);

  flattenedTranscript.score = computeScore(flattenedTranscript);
  flattenedTranscript.facilitator = getFacilitator(flattenedTranscript);
  flattenedTranscript.locale = flattenedTranscript.courseLocale
    ? flattenedTranscript.courseLocale.replace('_', '-')
    : null;

  return flattenedTranscript;
}

/**
  * flattens the transcript object and then computes the score.
  * @function computeScore
  * @param {Object} transcript - The transcript object to compute score for
  * @return {Object} - Returns a flattened transcript with the score computed
  */

export default createListingReducer({
  flattenMethod: flattenTranscript,
  successAction: actionTypes.USER_TRANSCRIPTS_SUCCESS,
  loadingAction: actionTypes.USER_TRANSCRIPTS_LOADING,
  errorAction: actionTypes.USER_TRANSCRIPTS_ERROR,
});
