import usersList from './usersList';
import usersListOptions from './usersListOptions';
import usersListUnarchive from './usersListUnarchive';
import usersListArchive from './usersListArchive';
import usersSecurityFieldList from './usersSecurityFieldList';

/**
 * @module reducers/users
 */

/**
  * @typedef Users
  * @type {Object}
  * @property {Object} options - the
  * {@link module:reducers/usersListOptions~UsersListOptions options} used to filter and sort
  * the data
  * @property {Object} list - the {@link module:reducers/usersList~UsersList list}
  * @property {Object} securityFieldsList - the security fields for the user}
  */

/**
 * users Reducer function
 * @function users
 * @param {Object} state - Defaults to {@link module:reducers/users~initialState initialState}
 * @param {Object} action - Action
 * @return {Users} The {@link module:reducers/users~Users users} reducers state
 */
export function users(state = {}, action = {}) {
  const options = usersListOptions(state.options, action);
  const list = usersList(state.list, action, options);
  const securityFieldsList = usersSecurityFieldList(state.securityFieldsList, action);

  return {
    options,
    list,
    securityFieldsList,
  };
}

/**
  * @typedef UnarchiveUsers
  * @type {Object}
  * @property {Object} unarchived -
  * {@link module:reducers/usersListUnarchive~usersListUnarchive unarchive} used to unarchive users
  */

/**
 * Unarchive Reducer function
 * @function unarchive
 * @param {Object} state - Defaults to {@link module:reducers/users~initialState initialState}
 * @param {Object} action - Action
 * @return {UnarchiveUsers} The {@link module:reducers/users~UnarchiveUsers unarchive}
 * reducers state
 */
export function unarchive(state, action) {
  const unarchived = usersListUnarchive(action);

  return {
    unarchived,
  };
}

/**
  * @typedef ArchiveUsers
  * @type {Object}
  * @property {Object} archive -
  * {@link module:reducers/usersListArchive~usersListArchive archive} used to archive users
  */

/**
 * Archive Reducer function
 * @function archive
 * @param {Object} state - Defaults to {@link module:reducers/users~initialState initialState}
 * @param {Object} action - Action
 * @return {ArchiveUsers} The {@link module:reducers/users~ArchiveUsers archive} reducers state
 */
export function archive(state, action) {
  const archived = usersListArchive(action);

  return {
    archived,
  };
}
