import actionTypes from 'constants/actionTypes';
import createListingReducer from './createListingReducer';
import createListOptionsReducer from './createListOptionsReducer';

export function groupAdmins(state = {}, action = {}) {
  const optionsReducer = createListOptionsReducer({
    loadingAction: actionTypes.GROUP_ADMINS_LIST_LOADING,
  });

  const listReducer = createListingReducer({
    successAction: actionTypes.GROUP_ADMINS_LIST_SUCCESS,
    loadingAction: actionTypes.GROUP_ADMINS_LIST_LOADING,
    errorAction: actionTypes.GROUP_ADMINS_LIST_ERROR,
  });

  const options = optionsReducer(state.options, action);
  const list = listReducer(state.list, action, options);

  return {
    options,
    list,
  };
}
