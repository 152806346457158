import _ from 'lodash';
import { format } from 'url';

import Cookies from 'cookies-js';
import AppConstants from '../constants/appconstants';

// used to construct the url in makeFetchCall
let client;
let fetchCallsInProgress = 0;
let callbacks = [];

/**
 * From: https://engineering.classdojo.com/blog/2017/01/12/integration-testing-react-redux/
 * Testing tool to allow us to wait till all fetch calls and actions are finished
 * @return {Promise} promise resolved when all fetch calls and actions are complete
 */
export function waitForApiCallsToFinish() {
  if (fetchCallsInProgress === 0) {
    return Promise.resolve();
  }
  return new Promise((resolve) => {
    callbacks.push(resolve);
  });
}

function checkApiCalls() {
  if (fetchCallsInProgress === 0) {
    callbacks.forEach((callback) => callback());
    callbacks = [];
  }
}
/**
 * Redux middleware to wait till all actions are completed
 * We mount this middleware only in testing.
 * @return {function} redux middleware action handler
 */
export function resolveAPICallbacksMiddleware() {
  return (next) => (action) => {
    // After this line, the store and views will have updated with any changes.
    // If we don't have any API calls outstanding at this point, it's safe to
    // say that we can continue with our tests.
    next(action);

    // `setTimeout` is in case the any code that runs as a result of this
    // action dispatches another API call.
    setTimeout(checkApiCalls, 0);
  };
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

function parseJSON(response) {
  return response.json().then((json) => (
    {
      headers: response.headers,
      status: response.status,
      data: json,
    }
  ))
    // for no response body
    .catch(() => ({
      headers: response.headers,
      status: response.status,
    }));
}

export function makeUrl(urlStr) {
  let url = urlStr;
  if (url.indexOf('http') === -1) {
    if (client) {
      url = ['', client, 'api', url].join('/');
    } else {
      url = ['', 'api', url].join('/');
    }
  }
  return url;
}

/* params is an object which maps a name to something else. */
export function getSortParams(params) {
  const data = {};
  const parts = params.map((item) => {
    const name = item[0];
    const direction = item[1];
    let part = name;

    if (Array.isArray(name)) {
      name.forEach((key, i) => {
        if (direction === 'DESC') {
          part[i] = `-${part[i]}`;
        }
      });
    } else if (direction === 'DESC') {
      part = `-${part}`;
    }
    return part;
  });

  if (parts.length) {
    data.sort = parts.join(',');
  }
  return data.sort;
}

/* params is an object which maps a name to an object containing op and value strings. */
export function getFilterParams(params) {
  const parts = {};

  Object.keys(params).forEach((filterName) => {
    const param = params[filterName];
    // Hack to remove searchFilter from the filters
    // searchFilter really should not be inside of filters
    // we should refactor the url to look like:
    // /users/?filters=archived:eq:true,learners:eq:true&page=1&searchFilter=worksite
    if (filterName === 'searchFilter') {
      return;
    }

    if (filterName === 'expand') {
      parts[filterName] = param;
    } else {
      parts[filterName] = `${param.op}:${param.value}`;
    }
  });
  return parts;
}

export function getQueryParams(settings) {
  const options = _.cloneDeep(settings);

  let queryObj = {
    per_page: options.rowsPerPage || AppConstants.PER_PAGE,
  };

  if (options.expand) {
    queryObj['expand[]'] = options.expand;
    delete options.expand;
  }

  if (options.sort) {
    queryObj.sort = getSortParams(options.sort);
    delete options.sort;
  }

  if (options.filters) {
    queryObj = _.merge(queryObj, getFilterParams(options.filters));
    delete options.filters;
  }

  if (options.fields) {
    queryObj.fields = options.fields.join(',');
    delete options.fields;
  }

  if (options.page) {
    queryObj.page = options.page;
    delete options.page;
  }

  if (options.format) {
    queryObj.format = options.format;
    delete options.format;
  }
  return queryObj;
}

export function makeFetchCall(baseUrl, options, acceptType = 'json') {
  fetchCallsInProgress += 1;

  const result = fetch(baseUrl, options)
    .then(checkStatus);

  const promise = acceptType === 'json' ? result.then(parseJSON) : result;
  promise.catch(() => { })
    .finally(() => {
      fetchCallsInProgress -= 1;
      setTimeout(checkApiCalls, 0);
    });
  return promise;
}

export function prepSettings(method, options) {
  const version = options.version || 'v1';
  const acceptType = options.acceptType || 'json';

  const types = {
    json: 'application/json',
    csv: 'text/csv; charset=UTF-8',
    inc: 'application/octet-stream',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const settings = _.merge({
    method,
    headers: {
      Accept: `application/vnd.alchemy.${version}+${acceptType}`,
      'Content-Type': types[acceptType],
      'X-Alt-Referer': window.location.pathname,
      ...(options.headers ? options.headers : {}),
    },
  }, options);

  let token = Cookies.get('XUserLogin');

  if (settings.token) {
    ({ token } = settings);
    delete settings.token;
  }

  if (token) {
    settings.headers['X-Auth-Token'] = token;
  }

  if (method !== 'GET') {
    settings.body = JSON.stringify(settings.data);
    delete settings.data;
  }

  return settings;
}

export function makeDirectLink(url, options = {}) {
  const settings = prepSettings('GET', options);
  const query = getQueryParams(settings);
  query.url_headers = JSON.stringify(settings.headers);

  const baseUrl = format({
    protocol: window.location.protocol,
    host: window.location.host,
    pathname: makeUrl(url),
    query,
  });

  return baseUrl;
}

function makeManagerFetchCall(method, url, options = {}) {
  const settings = prepSettings(method, options);
  const query = getQueryParams(settings);
  const baseUrl = format({
    protocol: window.location.protocol,
    host: window.location.host,
    pathname: makeUrl(url),
    query,
  });
  return makeFetchCall(baseUrl, settings, settings.acceptType);
}

export default {
  setClient(_client) {
    client = _client;
  },

  swapFilterMap(obj, map) {
    // Don't modify the params
    const filters = { ...obj };
    return _.forEach(filters, (value, key) => {
      if (map[key] && key !== map[key]) {
        filters[map[key]] = value;
        delete filters[key];
      }
    });
  },

  swapSortMap(arr, map) {
    return arr.map((element) => {
      const newKey = map[element[0]];
      const val = element;
      if (newKey && newKey !== val) {
        val[0] = newKey;
      }
      return val;
    });
  },

  get(url, options) {
    return makeManagerFetchCall('GET', url, options);
  },

  head(url, options) {
    return makeManagerFetchCall('HEAD', url, options);
  },

  post(url, options) {
    return makeManagerFetchCall('POST', url, options);
  },

  patch(url, options) {
    return makeManagerFetchCall('PATCH', url, options);
  },

  delete(url, options) {
    return makeManagerFetchCall('DELETE', url, options);
  },

  parseLinksHeader(header) {
    const links = {};
    if (header) {
      // Split parts by comma
      const parts = header.split(',');
      // Parse each part into a named link
      parts.forEach((part) => {
        const section = part.split(';');
        if (section.length !== 2) {
          throw new Error('section could not be split on \';\'');
        }
        const url = section[0].replace(/<(.*)>/, '$1').trim();
        const name = section[1].replace(/rel="(.*)"/, '$1').trim();
        links[name] = url;
      });
    }
    return links;
  },
};
