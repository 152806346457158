import actionTypes from '../constants/actionTypes';

/**
 * @module reducers/testSession
 */

/**
 * @constant
 * @type {Object}
 * @default
 */
const initialState = {
  isLoading: false,
  hasLoaded: false,
  hasError: false,
};

/**
  * @typedef Answer
  * @type {Object}
  * @property {number} id - Id of the answer in the database
  * @property {string} text - Answer Text
  */

/**
  * @typedef UserAnswer
  * @type {Object}
  * @property {number} id - Id of the user answer in the database
  * @property {number} answer_id - Id of the answer the user picked
  */

/**
  * @typedef Question
  * @type {Object}
  * @property {number} id - Id of the question in the database
  * @property {string} type - question type, i.e. true-false, etc...
  * @property {string} text - question text
  * @property {number} order - question order
  * @property {nummber} correct_answer - id of the correct answer of the question
  * @property {bool} is_remediation -  If this is true the question is a
  * remediation/feedback question
  * @property {Object} modifier - user object for the person who modified the question last
  * @property {Answer[]} answers - Array of {@link module:reducers/testSession~Answer Answers}
  * for the question
  * @property {UserAnswer} user_answer - The
  * {@link module:reducers/testSession~UserAnswer answer the user picked}
  */

/**
  * @typedef QuestionSection
  * @type {Object}
  * @property {number} id - Id of the section in the database
  * @property {string} name - name of the section
  * @property {number} order - order of the section
  * @property {Question[]} questions - {@link module:reducers/testSession~Question Questions}
  * in the section
  */

/**
  * @typedef QuestionGroup
  * @type {Question[]}
  * @property {Question} question - {@link module:reducers/testSession~Question Question}
  */

/**
  * @typedef TestSession
  * @type {Object}
  * @property {bool} isLoading - If this is true the test session result data
  * is currently being loaded
  * @property {bool} hasLoaded - If this is true then the test session result data
  * has been loaded or there is an error
  * @property {bool} hasError - If this is true then there is an error in the
  * {@link module:reducers~errors errors reducer}
  * @property {QuestionGroup[]?} questionGroups - Array of
  * {@link module:reducers/testSession~QuestionGroup QuestionGroup}
  * @property {other} otherProps - There are other properties here.
  * They are defined in the REST API documentation
  */

/**
 * This function will loop over every question_section in the test_session and
 * grabs every question from them. Then it will loop over every question and
 * build an object of arrays where the arrays are the question object and
 * the optional feedback questions if they are present
 * @function buildQuestionGroups
 * @param {array.<QuestionSection>} questionSections - Array of
 * {@link module:reducers/testSession~QuestionSection question section} objects
 * @return {array.<QuestionGroup>} Array of
 * {@link module:reducers/testSession~QuestionGroup QuestionGroup}
 * where the question group contains the question and any corresponding
 * feedback/redmidiation question
 */
function buildQuestionGroups(questionSections) {
  const questionGroups = {};
  let questions = [];
  questionSections.forEach((questionSection) => {
    questions = questions.concat(questionSection.questions);
  });

  let questionsGroup = [];
  let questionNumber = 1;
  let firstQuestion = true;

  questions.forEach((question, index) => {
    if (!question.is_remediation) {
      if (!firstQuestion) {
        questionGroups[questionNumber] = questionsGroup;
        questionsGroup = [];
        questionNumber += 1;
      }
      questionsGroup.push(question);
      firstQuestion = false;
    } else if (question.is_remediation) {
      questionsGroup.push(question);
    }
    if (index + 1 === questions.length) {
      questionGroups[questionNumber] = questionsGroup;
    }
  });

  return questionGroups;
}

/**
 * testSession Reducer function
 * @function testSession
 * @param {Object} state - Defaults to {@link module:reducers/testSession~initialState initialState}
 * @param {Object} action - Action
 * @param {Object} optionsReducer - options
 * @return {TestSession} The {@link module:reducers/testSession~TestSession testSession}
 * reducers state
 */
export default function testSession(state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.TEST_SESSION_SUCCESS:
      return {
        ...action.data,
        questionGroups: buildQuestionGroups(data.question_sections),
        isLoading: false,
        hasLoaded: true,
        hasError: false,
      };

    case actionTypes.TEST_SESSION_LOADING:
      return {
        isLoading: true,
        hasLoaded: false,
        hasError: false,
      };

    case actionTypes.TEST_SESSION_ERROR:
      return {
        isLoading: false,
        hasLoaded: true,
        hasError: true,
      };

    default:
      return state;
  }
}
