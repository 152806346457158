import actionTypes from '../constants/actionTypes';
import createListingReducer from './createListingReducer';

/**
  * @module reducers/coursesActivitiesList
  */

/**
  * coursesActivitiesList Reducer function
  * @function coursesActivitiesList
  * @param {Object} state - defaults to
  * {@link module:reducers/createListOptionsReducer~defaultInitialState}
  * @param {Object} action - Action
  * @param {Object} optionsReducer - options for the reducer
  * @return {Object} The reducer's state
  */
export default createListingReducer({
  singleSuccessAction: actionTypes.COURSE_FETCH_SUCCESS,
  successAction: actionTypes.COURSES_ACTIVITIES_SUCCESS,
  loadingAction: actionTypes.COURSES_ACTIVITIES_LOADING,
  errorAction: actionTypes.COURSES_ACTIVITIES_ERROR,
});
