import keymirror from 'keymirror';

export default {
  PER_PAGE: 50,
  ROW_COUNT: 49,

  // these are two prop types for the react-virtualized data table; might
  // be expanded as necessary
  LISTING_ROW_HEIGHT: 42,
  LISTING_HEADER_HEIGHT: 70,

  courseTypes: {
    NONE: '0',
    WBT: '1',
    IBT: '2',
    ILT: '2',
    SCB: '3',
    MIX: '4',
    SCORM: '8',
    SISTEM: '9',
    TEST: '10',
    SURVEY: '11',
    PARENT: '12',
    CREATOR: '16',
    OBSERVATION: '17',
    UPL: '5',
  },

  playActivityActionTypes: keymirror({
    PLAY_ACTIVITY_SOURCE_PAGE: null,
  }),

  deliveryOptions: {
    PLAYBOOK: 'playbook',
    ONLINE: 'online',
    PLAYER: 'player',
  },
  LEFT_NAV_BAR_WIDTH: 240,
  RIGHT_NAV_BAR_WIDTH: 355,
  FILE_UPLOAD_SIZE: 1048576,
};
