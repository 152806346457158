import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { resolveAPICallbacksMiddleware } from 'utils/fetch-utils';

import rootReducer from '../reducers';

const middleware = [thunk];

if (TESTING) {
  middleware.push(resolveAPICallbacksMiddleware);
}

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const finalCreateStore = composeEnhancers(applyMiddleware(...middleware))(createStore);
/* eslint-enable */

export default function configureStore(initialState) {
  return finalCreateStore(rootReducer, initialState);
}
