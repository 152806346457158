import actionTypes from '../constants/actionTypes';

const initialState = {};

export default function category(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.GET_CATEGORY_SUCCESS:
      return action.response.data;

    default:
      return state;
  }
}
