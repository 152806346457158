import actionTypes from 'constants/actionTypes';
import createListingReducer from './createListingReducer';
import createListOptionsReducer from './createListOptionsReducer';

/**
 * @module reducers/supervisingGroupsOptions
 */

export default function supervisingGroupsOptions(state = {}, action = {}) {
  const optionsReducer = createListOptionsReducer({
    loadingAction: actionTypes.SUPERVISING_GROUPS_OPTIONS_LOADING,
  });
  const listingReducer = createListingReducer({
    successAction: actionTypes.SUPERVISING_GROUPS_OPTIONS_SUCCESS,
    loadingAction: actionTypes.SUPERVISING_GROUPS_OPTIONS_LOADING,
    errorAction: actionTypes.SUPERVISING_GROUPS_OPTIONS_ERROR,
  });

  const options = optionsReducer(state.options, action);

  const list = listingReducer(state.list, action, options);
  return {
    list,
    options,
  };
}
