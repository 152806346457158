import actionTypes from '../constants/actionTypes';

const initialState = {
  isLoading: false,
  hasLoaded: false,
  hasError: false,
  isCompletingCorrectiveAction: false,
};

function buildQuestionGroups(questionSections) {
  const questionGroups = {};
  let questionNumber = 1;
  questionSections.forEach((questionSection) => {
    questionSection.questions.forEach((question) => {
      questionGroups[questionNumber] = [question];
      questionNumber += 1;
    });
  });
  return questionGroups;
}

export default function observationSession(state = initialState, action = {}) {
  const { type } = action;
  switch (type) {
    case actionTypes.OBSERVATION_SESSION_SUCCESS: {
      const { data } = action;
      return {
        ...data,
        questionGroups: buildQuestionGroups(data.question_sections),
        isLoading: false,
        hasLoaded: true,
        hasError: false,
        isCompletingCorrectiveAction: false,
      };
    }
    case actionTypes.OBSERVATION_SESSION_LOADING:
      return {
        ...state,
        isLoading: true,
        hasLoaded: false,
        hasError: false,
        isCompletingCorrectiveAction: false,
      };

    case actionTypes.OBSERVATION_SESSION_ERROR:
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        hasError: true,
        isCompletingCorrectiveAction: false,
      };

    case actionTypes.COMPLETE_PENDING_CORRECTIVE_ACTION_LOADING:
      return {
        ...state,
        isLoading: true,
        hasLoaded: false,
        hasError: false,
        isCompletingCorrectiveAction: true,
      };

    case actionTypes.COMPLETE_PENDING_CORRECTIVE_ACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        hasError: false,
        isCompletingCorrectiveAction: true,
      };
    }
    case actionTypes.COMPLETE_PENDING_CORRECTIVE_ACTION_ERROR:
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        hasError: true,
        isCompletingCorrectiveAction: false,
      };

    default:
      return state;
  }
}
