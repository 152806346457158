import coursesActivitiesList from './coursesActivitiesList';
import coursesActivitiesOptions from './coursesActivitiesOptions';

/**
 * @module reducers/coursesActivities
 */

/**
 * coursesActivities Reducer function
 * @function coursesActivities
 * @param {Object} state - Defaults to {}
 * @param {Object} action - Action
 * @param {Object} optionsReducer - options
 * @return {CoursesActivitiesList} The coursesActivities reducers state
 */
export default function coursesActivities(state = {}, action = {}) {
  const options = coursesActivitiesOptions(state.options, action);
  const list = coursesActivitiesList(state.list, action, options);

  return {
    options,
    list,
  };
}
