import actionTypes from '../constants/actionTypes';

const initialState = {};

export default function errors(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.CONFIG_LOAD_ONE_ERROR:
    case actionTypes.ADD_CATEGORY_ERROR:
    case actionTypes.ADD_USER_ERROR:
    case actionTypes.ADD_COURSE_ERROR:
    case actionTypes.APPROVE_USER_ERROR:
    case actionTypes.CATEGORIES_LIST_ERROR:
    case actionTypes.CATEGORY_HISTORY_ERROR:
    case actionTypes.COMPLETE_PENDING_CORRECTIVE_ACTION_ERROR:
    case actionTypes.COURSES_ACTIVITIES_ERROR:
    case actionTypes.DELETE_CATEGORY_ERROR:
    case actionTypes.EVAL_SESSION_ERROR:
    case actionTypes.OBSERVATION_SESSION_ERROR:
    case actionTypes.OBSERVATION_ERROR:
    case actionTypes.PARENT_COURSES_LIST_ERROR:
    case actionTypes.PATCH_CATEGORY_ERROR:
    case actionTypes.PATCH_COURSE_ERROR:
    case actionTypes.PATCH_CURRENT_USER_ERROR:
    case actionTypes.PATCH_PARENT_COURSE_ERROR:
    case actionTypes.PATCH_USER_ERROR:
    case actionTypes.PATCH_REPORT_RUNNER_ERROR:
    case actionTypes.PATCH_SYSTEM_GROUP_ERROR:
    case actionTypes.PATCH_GROUP_ADMINS_ERROR:
    case actionTypes.PENDING_CORRECTIVE_ACTIONS_LIST_ERROR:
    case actionTypes.PENDING_USERS_LIST_ERROR:
    case actionTypes.REDEEM_TOKEN_ERROR:
    case actionTypes.REPORT_RESULT_ERROR:
    case actionTypes.SUPERVISED_GROUPS_OPTIONS_ERROR:
    case actionTypes.SUPERVISING_GROUPS_OPTIONS_ERROR:
    case actionTypes.GROUPS_LIST_ERROR:
    case actionTypes.GROUP_ADMINS_LIST_ERROR:
    case actionTypes.GROUP_MEMBERS_ERROR:
    case actionTypes.TEST_SESSION_ERROR:
    case actionTypes.USERS_ARCHIVE_ERROR:
    case actionTypes.USERS_LIST_ERROR:
    case actionTypes.USERS_UNARCHIVE_ERROR:
    case actionTypes.USER_HISTORY_ERROR:
    case actionTypes.USER_RESET_PASSWORD_ERROR:
    case actionTypes.USER_SECURITY_FIELDS_ERROR:
    case actionTypes.USER_TRANSCRIPTS_ERROR:
    case actionTypes.DELETE_USER_ERROR:
    case actionTypes.USERS_MERGE_ERROR:
    case actionTypes.LEARNING_PLANS_LIST_ERROR:
    case actionTypes.LEARNING_PLAN_SUBSCRIPTIONS_OPTIONS_LIST_ERROR:
    case actionTypes.PATCH_COURSES_ERROR:
    case actionTypes.LEARNING_PLAN_SUBSCRIPTIONS_LIST_ERROR:
    case actionTypes.PATCH_LEARNING_PLAN_ERROR:
    case actionTypes.PATCH_LEARNING_PLAN_SUBSCRIPTIONS_ERROR:
    case actionTypes.ADD_LEARNING_PLAN_ERROR:
    case actionTypes.GET_REPORTS_ERROR:
    case actionTypes.ADD_CHECKPOINT_ERROR:
    case actionTypes.CREATE_JWT_TOKEN_ERROR:
    case actionTypes.ADD_SYSTEM_GROUP_ERROR:
    case actionTypes.DELETE_SYSTEM_GROUP_ERROR:
    case actionTypes.GET_CHECKPOINT_ERROR:
    case actionTypes.SERVER_EXCEPTION:
    case actionTypes.REPORT_RUNNER_ERROR: {
      return {
        status: action.error.status,
        statusText: action.error.statusText,
        body: action.error.body,
      };
    }
    case actionTypes.CLEAR_ERROR:
      return {};
    default:
      return state;
  }
}
