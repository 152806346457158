import { flattenHistory } from 'utils/flatten-data';
import actionTypes from 'constants/actionTypes';
import createListingReducer from './createListingReducer';

export default createListingReducer({
  successAction: actionTypes.USER_HISTORY_SUCCESS,
  loadingAction: actionTypes.USER_HISTORY_LOADING,
  errorAction: actionTypes.USER_HISTORY_ERROR,
  flattenMethod: flattenHistory,
});
