import actionTypes from '../constants/actionTypes';

/**
 * @module reducers/usersListUnarchive
 */

/**
 * @constant
 * @type {Object}
 * @default
 */
const initialState = {
  isProcessing: false,
  hasProcessed: false,
  hasError: false,
};
/**
 * @typedef usersListUnarchive
 * @type {Object}
  * @property {bool} isProcessing - If true, unarchive users are currently being processed
  * @property {bool} hasProcessed - If true, unarchive users has been unarchived or
  * there is an error
  * @property {bool} hasError - If true, error in {@link module:reducers~errors errors reducer}
 */

/**
 * usersListUnarchive Reducer function
 * @function usersListUnarchive
 * @param {Object} action - Action
 * @return {usersListUnarchive} The
 * {@link module:reducers/usersListUnarchive~UsersList usersListUnarchive} reducers state
 */
export default function usersListUnarchive(action) {
  const { type } = action;
  switch (type) {
    case actionTypes.USERS_UNARCHIVE_SUCCESS: {
      return {
        isProcessing: false,
        hasProcessed: true,
        hasError: false,
      };
    }
    case actionTypes.USERS_UNARCHIVE_PROCESSING:
      return {
        isProcessing: true,
        hasProcessed: false,
        hasError: false,
      };

    case actionTypes.USERS_UNARCHIVE_ERROR:
      return {
        isProcessing: false,
        hasProcessed: true,
        hasError: true,
      };

    default:
      return initialState;
  }
}
