import actionTypes from '../constants/actionTypes';

const initialState = {
  isLoading: false,
  hasLoaded: false,
};

export default function redeemPasswordToken(state = initialState, action = {}) {
  const { type } = action;
  switch (type) {
    case actionTypes.REDEEM_TOKEN_LOADING: {
      return {
        isLoading: true,
        hasLoaded: false,
      };
    }
    case actionTypes.REDEEM_TOKEN_SUCCESS: {
      return {
        isLoading: false,
        hasLoaded: true,
      };
    }
    default:
      return state;
  }
}
