import actionTypes from 'constants/actionTypes';
import createListingReducer from './createListingReducer';

/**
 * @module reducers/usersPendingSecurityFieldList
 */

/**
 * @typedef User
 * @type {Object}
 * @property {number} id - Id of the user in the database
 * @property {Object[]} barcodes - Array of barcodes
 * @property {date} date_of_hire - Date the user was hired
 * @property {date} date_of_birth - Date the user was born
 * @property {number} ssn - social security number of the user
 * @property {number} base_rate - base pay rate for the user
 * @property {array.string} roles - The roles the user has.
 * @property {array.object} supervisingGroups - The groups the user supervises.
 */

export default createListingReducer({
  singleSuccessAction: actionTypes.PENDING_USER_SECURITY_FIELDS_FETCH_SUCCESS,
  successAction: 'UNUSED_ACTION',
  loadingAction: 'UNUSED_ACTION',
  errorAction: actionTypes.PENDING_USER_SECURITY_FIELDS_ERROR,
});
