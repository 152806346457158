import actionTypes from 'constants/actionTypes';

const initialState = {
};

export default function configs(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.CONFIG_LOAD_ALL_SUCCESS: {
      const tunables = {};
      Object.keys(action.allTunables).forEach((index) => {
        const currentTunable = action.allTunables[index];
        tunables[currentTunable.id] = currentTunable;
      });
      return tunables;
    }
    case actionTypes.CONFIG_LOAD_ONE_SUCCESS: {
      const { id, value, category } = action.config;
      const tunable = {};
      tunable[id] = { id, value, category };
      return { ...state, ...tunable };
    }
    default:
      return state;
  }
}
