import usersPendingList from './usersPendingList';
import usersPendingOptions from './usersPendingOptions';
import usersPendingSecurityFieldList from './usersPendingSecurityFieldList';

/**
 * @module reducers/usersPending
 */

/**
  * @typedef UsersPending
  * @type {Object}
  * @property {Object} options - the
  * {@link module:reducers/usersPendingOptions~UsersPendingOptions options} used to filter and sort
  * the data
  * @property {Object} list - the {@link module:reducers/usersPendingList~UsersPendingList list}
  * @property {Object} securityFieldsList - the security fields for the user}
  */

/**
 * Users Pending Reducer function
 * @function usersPending
 * @param {Object} state - Defaults to {@link module:reducers/users~initialState initialState}
 * @param {Object} action - Action
 * @return {Users} The {@link module:reducers/users~Users users} reducers state
 */
export default function usersPending(state = {}, action = {}) {
  const options = usersPendingOptions(state.options, action);
  const list = usersPendingList(state.list, action, options);
  const securityFieldsList = usersPendingSecurityFieldList(state.securityFieldsList, action);
  return {
    options,
    list,
    securityFieldsList,
  };
}
