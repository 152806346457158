import actionTypes from 'constants/actionTypes';
import createListOptionsReducer from './createListOptionsReducer';

/**
 * @module reducers/usersPendingOptions
 */

export default createListOptionsReducer({
  loadingAction: actionTypes.PENDING_USERS_LIST_LOADING,
  resetConcatActions: [
    actionTypes.PATCH_USER_SUCCESS,
    actionTypes.APPROVE_USER_SUCCESS,
    actionTypes.DELETE_USER_SUCCESS,
    actionTypes.USERS_MERGE_SUCCESS,
  ],
});
