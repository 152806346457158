import actionTypes from '../constants/actionTypes';

const initialState = {
  open: false,
  url: null,
  switchToReact: null,
};

export default function configs(state = initialState, action = {}) {
  const { type, data } = action;
  switch (type) {
    case actionTypes.SHOW_SWITCH_BAR: {
      return {
        open: true,
        url: data.url,
        switchToReact: data.switchToReact,
      };
    }
    case actionTypes.HIDE_SWITCH_BAR: {
      return {
        open: false,
        url: null,
        switchToReact: null,
      };
    }
    default:
      return state;
  }
}
