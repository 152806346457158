import actionTypes from '../constants/actionTypes';

const initialState = {
  isLoading: false,
  hasLoaded: false,
  hasError: false,
};

export default function observation(state = initialState, action = {}) {
  const { type } = action;
  switch (type) {
    case actionTypes.OBSERVATION_SUCCESS: {
      const { data } = action;
      return {
        ...data,
        isLoading: false,
        hasLoaded: true,
        hasError: false,
      };
    }
    case actionTypes.OBSERVATION_LOADING:
      return {
        ...state,
        isLoading: true,
        hasLoaded: false,
        hasError: false,
      };

    case actionTypes.OBSERVATION_ERROR:
      return {
        isLoading: false,
        hasLoaded: true,
        hasError: true,
      };

    default:
      return state;
  }
}
