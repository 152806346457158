import actionTypes from '../constants/actionTypes';
import createListingReducer from './createListingReducer';
import createListOptionsReducer from './createListOptionsReducer';

export default function learningPlanSubscriptions(state = {}, action = {}) {
  const optionsReducer = createListOptionsReducer({
    loadingAction: actionTypes.LEARNING_PLAN_SUBSCRIPTIONS_LIST_LOADING,
    resetConcatActions: [
      actionTypes.PATCH_LEARNING_PLAN_SUBSCRIPTIONS_SUCCESS,
      actionTypes.DELETE_LEARNING_PLAN_SUBSCRIPTIONS_SUCCESS,
    ],
  });
  const listingReducer = createListingReducer({
    singleSuccessAction: actionTypes.SUBSCRIPTION_FETCH_SUCCESS,
    successAction: actionTypes.LEARNING_PLAN_SUBSCRIPTIONS_LIST_SUCCESS,
    loadingAction: actionTypes.LEARNING_PLAN_SUBSCRIPTIONS_LIST_LOADING,
    errorAction: actionTypes.LEARNING_PLAN_SUBSCRIPTIONS_LIST_ERROR,
  });

  const options = optionsReducer(state.options, action);
  const list = listingReducer(state.list, action, options);

  return {
    list,
    options,
  };
}
