import actionTypes from '../constants/actionTypes';

const initialState = {
  powers: {},
};

export default function userPowers(state = initialState, action = {}) {
  const { type, powers } = action;
  switch (type) {
    case actionTypes.RECEIVE_POWERS: {
      return {
        powers,
      };
    }
    default:
      return state;
  }
}
