import userHistoryList from './userHistoryList';
import userHistoryOptions from './userHistoryOptions';

export default function userHistory(state = {}, action = {}) {
  const options = userHistoryOptions(state.options, action);
  const list = userHistoryList(state.list, action, options);

  return {
    options,
    list,
  };
}
