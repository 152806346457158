import _ from 'lodash';

/**
 * @constant
 * @type {Object}
 * @default
 * @property {bool} concatResults - If true, data should replace existing data
 * rather than being concatenated to the end of it
 * @property {Object} filters - Specifies what should be used to filter data
 * @property {bool} hasOptions - If this is true then the sort or
 * filters properties have been set
 * @property {array} sort - Array of fields and directions (['field', 'direction'])
 * for which data should be sorted
 */
export const defaultInitialState = {
  concatResults: false,
  filters: {},
  hasOptions: false,
  sort: [],
  forceFreshResults: false,
};

/**
 * Generates a default reducer for listing options
 * @param {Object} options - Options for the reducer.
 * @param {Object} options.initialState - The initial state of the reducer
 * Defaults to {@link defaultInitialState}
 * @param {string} options.loadingAction - The loading action
 * @param {array} options.resetConcatActions - Actions that will set concatResults
 * to false
 * @return {function} The generated reducer function
 */
export default function createListOptionsReducer({
  initialState = defaultInitialState,
  loadingAction,
  resetConcatActions = [],
}) {
  /**
   * @param {Object} state - The state of this reducer, Defaults to the provided initalState
   * @param {Object} action - The current action
   * @param {Object} action.response - The action's response including headers and data
   * @param {Object} action.options - The options for the reducers
   * @param {array} action.options.sort - Array of fields and directions (['field', 'direction'])
   * for which data should be sorted
   * @param {Object} action.options.filters - specifies what should be used to filter data
   * @param {bool} action.options.concatResults - If true, data should replace existing data
   * rather than being concatenated to the end of it
   * @param {bool} action.options.hasOptions - If this is true then the sort or
   * filters properties have been set
   * @return {Object} The reducers new state
   */
  return function ListingOptionsReducer(
    state = initialState,
    { type, options } = {},
  ) {
    if (resetConcatActions.includes(type)) {
      return {
        ...state,
        forceFreshResults: true,
      };
    }
    switch (type) {
      case loadingAction: {
        const {
          sort = [], filters = {}, storeFilters = true,
        } = options;

        let concatResults = false;
        if (options.concatResults !== undefined) {
          ({ concatResults } = options);
        } else if (
          !state.forceFreshResults
          && _.isEqual(state.filters, filters)
          && _.isEqual(state.sort, sort)
        ) {
          concatResults = true;
        }

        // allows for the same listing store to be used in other places than a
        // listing but not affect the listings filters
        if (!storeFilters) {
          return {
            ...state,
            concatResults,
          };
        }

        return {
          sort,
          filters,
          concatResults,
          hasOptions: true,
          forceFreshResults: false,
        };
      }
      default:
        return state;
    }
  };
}
