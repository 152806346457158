import { getOneConfig } from 'services/fetch/configs';
import actionTypes from 'constants/actionTypes';

export function loadConfiguration(allTunables) {
  return {
    type: actionTypes.CONFIG_LOAD_ALL_SUCCESS,
    transactionNumber: 0,
    allTunables,
  };
}

function loadingOneConfiguration(configId) {
  return {
    type: actionTypes.CONFIG_LOAD_ONE_LOADING,
    id: configId,
  };
}

function loadOneConfiguration(config) {
  return {
    type: actionTypes.CONFIG_LOAD_ONE_SUCCESS,
    config,
  };
}

function loadOneConfigurationError(response, json) {
  return {
    type: actionTypes.CONFIG_LOAD_ONE_ERROR,
    error: {
      status: response.status,
      body: json,
    },
  };
}

export function getConfigurationIfNeeded(id, forceFetch = false) {
  return (dispatch, getState) => {
    if (!forceFetch) {
      const { configs } = getState();
      if (configs && configs[id]) {
        return false;
      }
    }
    dispatch(loadingOneConfiguration(id));
    return getOneConfig(id).then((responseJSON) => {
      dispatch(loadOneConfiguration(responseJSON.data));
      return responseJSON;
    }).catch((exception) => {
      if (exception.response) {
        return exception.response.json().then((json) => {
          dispatch(loadOneConfigurationError(exception.response, json));
          return json;
        });
      }
      return Promise.reject(exception);
    });
  };
}
