import userTranscriptsList from './userTranscriptsList';
import userTranscriptsListOptions from './userTranscriptsListOptions';

/**
 * @module reducers/userTranscripts
 */

/**
  * @typedef UserTranscripts
  * @type {Object}
  * @property {Object} options - the
  *   {@link module:reducers/userTranscriptsListOptions~UserTranscriptsListOptions options}
  *   used to filter and sort the data
  * @property {Object} list - the
  *   {@link module:reducers/userTranscriptsList~UserTranscriptsList list}
  */

/**
 * userTranscripts Reducer function
 * @function userTranscripts
 * @param {Object} state - Defaults to
 *  {@link module:reducers/userTranscripts~initialState initialState}
 * @param {Object} action - Action
 * @return {UserTranscripts} The
 *  {@link module:reducers/userTranscripts~UserTranscripts userTranscripts} reducers state
 */
export default function userTranscripts(state = {}, action = {}) {
  const options = userTranscriptsListOptions(state.options, action);
  const list = userTranscriptsList(state.list, action, options);

  return {
    options,
    list,
  };
}
