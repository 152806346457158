import actionTypes from '../constants/actionTypes';
import createListOptionsReducer from './createListOptionsReducer';

/**
 * @module reducers/userTranscriptsListOptions
 */

export default createListOptionsReducer({
  loadingAction: actionTypes.USER_TRANSCRIPTS_LOADING,
});
