import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import categories from './categories';
import category from './category';
import configs from './configs';
import cookies from './cookies';
import coursesActivities from './coursesActivities';
import errors from './errors';
import evalSession from './evalSession';
import observationSession from './observationSession';
import observation from './observation';
import pendingCorrectiveActions from './pendingCorrectiveActions';
import snackbar from './snackbar';
import groups from './groups';
import { groupAdmins } from './groupAdmins';
import groupMembers from './groupMembers';
import supervisedGroupsOptions from './supervisedGroupsOptions';
import testSession from './testSession';
import userHistory from './userHistory';
import userPowers from './userPowers';
import userTranscripts from './userTranscripts';
import supervisingGroupsOptions from './supervisingGroupsOptions';
import { users, archive, unarchive } from './users';
import usersPending from './usersPending';
import redeemPasswordToken from './redeemPasswordToken';
import categoryHistory from './categoryHistory';
import parentCourses from './parentCourses';
import courseHistory from './courseHistory';
import learningPlans from './learningPlans';
import learningPlanHistory from './learningPlanHistory';
import learningPlanSubscriptions from './learningPlanSubscriptions';
import switchBar from './switchBar';

const rootReducer = combineReducers({
  archive,
  categories,
  category,
  categoryHistory,
  configs,
  cookies,
  courseHistory,
  coursesActivities,
  errors,
  evalSession,
  form: formReducer,
  learningPlanHistory,
  learningPlanSubscriptions,
  learningPlans,
  observation,
  observationSession,
  parentCourses,
  pendingCorrectiveActions,
  redeemPasswordToken,
  snackbar,
  groups,
  groupMembers,
  groupAdmins,
  supervisedGroupsOptions,
  supervisingGroupsOptions,
  switchBar,
  testSession,
  unarchive,
  userHistory,
  userPowers,
  userTranscripts,
  users,
  usersPending,
});

export default rootReducer;
