import actionTypes from '../constants/actionTypes';

const initialState = {
  isLoading: false,
  hasLoaded: false,
  hasError: false,
};

function buildQuestionGroups(questionSections) {
  const questionGroups = {};
  let questionNumber = 1;
  questionSections.forEach((questionSection) => {
    questionSection.questions.forEach((question) => {
      questionGroups[questionNumber] = [question];
      questionNumber += 1;
    });
  });
  return questionGroups;
}

export default function evalSession(state = initialState, action = {}) {
  const { data, type } = action;
  switch (type) {
    case actionTypes.EVAL_SESSION_SUCCESS:
      return {
        ...action.data,
        questionGroups: buildQuestionGroups(data.question_sections),
        isLoading: false,
        hasLoaded: true,
        hasError: false,
      };

    case actionTypes.EVAL_SESSION_LOADING:
      return {
        isLoading: true,
        hasLoaded: false,
        hasError: false,
      };

    case actionTypes.EVAL_SESSION_ERROR:
      return {
        isLoading: false,
        hasLoaded: true,
        hasError: true,
      };

    default:
      return state;
  }
}
