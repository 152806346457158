import actionTypes from 'constants/actionTypes';

/**
 * @module reducers/snackbar
 */

/**
 * @constant
 * @type {Object}
 * @default
 */
const initialState = {
  open: false,
};

/**
 * Snackbar reducer. Returns an object with open true and provided options if
 * a SHOW_SNACKBAR action is dispatched. Returns an object with open false if a
 * CLOSE_SNACKBAR action is dispatched.
 * @function snackbar
 * @param {Object} state - Defaults to {@link module:reducers/snackbar~initialState initialState}.
 * @param {Object} action - Action dispatched.
 * @return {Object} An object with open and options keys.
 */
export default function snackbar(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SHOW_SNACKBAR:
      return {
        open: true,
        options: {
          variant: 'success',
          ...action.options,
        },
      };

    case actionTypes.CLOSE_SNACKBAR:
      return {
        ...state,
        open: false,
      };

    default:
      return state;
  }
}
