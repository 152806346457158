import actionTypes from 'constants/actionTypes';
import createListingReducer from './createListingReducer';

/**
 * @module reducers/usersList
 */

/**
 * @typedef User
 * @type {Object}
 * @property {number} id - Id of the user in the database
 * @property {string} username -
 * @property {string} first_name - User first name
 * @property {string} middle_name - User middle name
 * @property {string} last_name - User last name
 * @property {string} user_id - Customer defined unique user id
 * @property {Object} roles - Object of roles that the user is associated to
 * @property {string} locale - Locale used when displaying UI to the user
 * @property {string} course_locale - Locale used as default filter for catalog
 * @property {string} email - when the training started
 * @property {string} position - when the training ended
 * @property {number} validation - -1: rejected, 0: not-validated, 1: validated
 * @property {boolean} archived -
 * @property {string} timezone - Preferred time zone for the display of date/time. In order,
 * it is the user's chosen time zone, the time zone of the user's work site, or the server
 * time zone.
 * @property {Object[]} addresses - Array of address objects
 * @property {Object[]} phone_numbers - Array of phone number objects
 * @property {Object[]} barcodes - Array of barcodes
 * @property {Object} worksite - Group object representing the worksite the user is in
 * @property {Object[]} groups - The system and corporate groups the user is in
 * @property {Object[]} supervising_groups - Array of groups that the user supervises
 * @property {boolean} must_change_password -
 * @property {date} date_of_hire - Date the user was hired
 * @property {date} date_of_birth - Date the user was born
 * @property {date} created_at - Date the user was created
 * @property {date} modified_at - Date user was last modified
 * @property {number} ssn - social security number of the user
 * @property {number} base_rate - base pay rate for the user
 * @property {Object} pa_code - Group object representing the PA Codes the user is in
 */

export default createListingReducer({
  singleSuccessAction: actionTypes.USER_FETCH_SUCCESS,
  successAction: actionTypes.USERS_LIST_SUCCESS,
  loadingAction: actionTypes.USERS_LIST_LOADING,
  errorAction: actionTypes.USERS_LIST_ERROR,
});
