import actionTypes from '../constants/actionTypes';
import createListingReducer from './createListingReducer';
import createListOptionsReducer from './createListOptionsReducer';

export default function categories(state = {}, action = {}) {
  const optionsReducer = createListOptionsReducer({
    loadingAction: actionTypes.CATEGORIES_LIST_LOADING,
    resetConcatActions: [
      actionTypes.ADD_CATEGORY_SUCCESS,
      actionTypes.PATCH_CATEGORY_SUCCESS,
      actionTypes.DELETE_CATEGORY_SUCCESS,
    ],
  });
  const listingReducer = createListingReducer({
    singleSuccessAction: actionTypes.CATEGORY_FETCH_SUCCESS,
    successAction: actionTypes.CATEGORIES_LIST_SUCCESS,
    loadingAction: actionTypes.CATEGORIES_LIST_LOADING,
    errorAction: actionTypes.CATEGORIES_LIST_ERROR,
  });

  const options = optionsReducer(state.options, action);

  const list = listingReducer(state.list, action, options);
  return {
    list,
    options,
  };
}
