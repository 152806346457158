import actionTypes from '../constants/actionTypes';
import createListingReducer from './createListingReducer';
import createListOptionsReducer from './createListOptionsReducer';

export default function parentCourses(state = {}, action = {}) {
  const optionsReducer = createListOptionsReducer({
    loadingAction: actionTypes.PARENT_COURSES_LIST_LOADING,
    resetConcatActions: [
      actionTypes.PATCH_PARENT_COURSE_SUCCESS,
    ],
  });
  const listingReducer = createListingReducer({
    singleSuccessAction: actionTypes.PARENT_COURSE_FETCH_SUCCESS,
    successAction: actionTypes.PARENT_COURSES_LIST_SUCCESS,
    loadingAction: actionTypes.PARENT_COURSES_LIST_LOADING,
    errorAction: actionTypes.PARENT_COURSES_LIST_ERROR,
  });

  const options = optionsReducer(state.options, action);

  const list = listingReducer(state.list, action, options);
  return {
    list,
    options,
  };
}
