import { flattenHistory } from 'utils/flatten-data';
import actionTypes from '../constants/actionTypes';
import createListingReducer from './createListingReducer';
import createListOptionsReducer from './createListOptionsReducer';

export default function categoryHistory(state = {}, action = {}) {
  const optionsReducer = createListOptionsReducer({
    loadingAction: actionTypes.CATEGORY_HISTORY_LOADING,
  });
  const listingReducer = createListingReducer({
    successAction: actionTypes.CATEGORY_HISTORY_SUCCESS,
    loadingAction: actionTypes.CATEGORY_HISTORY_LOADING,
    errorAction: actionTypes.CATEGORY_HISTORY_ERROR,
    flattenMethod: flattenHistory,
  });

  const options = optionsReducer(state.options, action);

  const list = listingReducer(state.list, action, options);
  return {
    list,
    options,
  };
}
