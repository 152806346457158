import Cookies from 'cookies-js';

Cookies.default = {
  path: `${window.URL_ROOT}/`,
};

export function setCookie(name, value, expireAt) {
  Cookies.set(name, value, expireAt);
  return {
    type: 'setCookie',
    name,
    value,
  };
}

export function getCookie(name) {
  const cookieValue = Cookies.get(name);
  return {
    type: 'getCookie',
    name,
    value: cookieValue,
  };
}
